<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>Опросы
            <RegionAndDepartmentPicker v-model="$regionAndDepartmentInfo"></RegionAndDepartmentPicker>
          </h1>
          <div v-if="activeConfigs"
               class="czn-link">
            <div>Ссылки на анкетирования, проводящиеся на данный момент:</div>
            <table>
              <tr v-for="config of activeConfigs" :key="config.id">
                <td>{{config.id}}. {{ config.title }}</td>
                <td>
                  <v-btn
                      class="btn btn-outline"
                      text
                      style="margin-right: 50px"
                      @click="navigateToAnkets(config.id)">
                    Анкеты
                  </v-btn>
                </td>
                <td><a :href="anketLink(config.id)" target="_blank">{{ anketLink(config.id) }}</a></td>
                <td>
                  <v-btn outlined @click="copyLink(anketLink(config.id))" title="Скопировать в буфер обмена">
                    <v-icon class="icon-item icon-content-copy"></v-icon>
                  </v-btn>
                </td>
              </tr>
            </table>
          </div>
          <div v-else class="czn-link">
            <div>На данный момент анкетирование не проводится</div>
          </div>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<script>

import RegionAndDepartmentPicker from "@/components/RegionAndDepartmentPicker";
import api from "@/modules/api";

export default {
  name: 'AnketsConfigsList',
  props: {},
  components: {
    RegionAndDepartmentPicker
  },
  data() {
    return {
      activeConfigs: [],
      inactiveConfigs: [],
      apiLoaded: false,
      errorText: null
    };
  },

  filters: {},

  methods: {
    async loadAnketConfigs() {
      const req = await api.get('/ankets/getConfigsList')
      if (req.ok) {
        const configs = req.payload
        this.classifyConfigs(configs)
      } else {
        this.errorText = "Не удается загрузить данные с сервера"
      }
    },

    classifyConfigs(configs) {
      configs = configs.map(config => {
        const configObject = JSON.parse(config.anketJson)
        return {
          id: config.id,
          active: config.active,
          title: configObject.title,
          description: configObject.description,
          modifiedDate: config.modifiedDate,
        }
      })
      this.activeConfigs = configs.filter(config=>config.active)
      this.inactiveConfigs = configs.filter(config=>!config.active)
    },

    copyLink(link) {
      navigator.clipboard.writeText(link);
    },

    anketLink(anketConfigId){
      return 'https://new.profczn.ru/' + anketConfigId + '?id=' + this.$regionAndDepartmentInfo?.department?.id
    },

    navigateToAnkets(configId){
      this.$router.push('/ankets/register?configId=' + configId)
    }
  },

  async beforeMount() {
    await this.loadAnketConfigs()
    this.apiLoaded = true
  },
};
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
.czn-link {
  margin-top: 16px;
  margin-bottom: 35px;
  font-size: 16px;

  div {
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    color: #1C1C1F;
    //margin-bottom: 5px;
  }

  table {
    width: 80%;
    margin-left: 35px;

    td {
      text-align: left;
      vertical-align: middle;
      padding: 5px;

      &:last-child {
        padding-right: 0;
        width: 40px;
      }

      &:nth-child(2) {
        text-align: right;
      }
    }
  }

  .v-btn {
    margin-left: 8px;
    max-height: 30px;
    min-width: 30px;
    padding: 0 2px;
  }
}
</style>
